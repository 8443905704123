export const DEFAULT_COLOR = '#FFFFFF'
export const PRIMARY_COLOR = '#003d5b'
export const SECONDARY_COLOR = '#007380'
export const SECONDARY_COLOR_LIGHT = '#25E2CC'
export const SECONDARY_COLOR_DARKER = '#5EBDD4'
export const NEUTRAL_LIGHT = '#F2F2F2'
export const DANGER_COLOR = '#B03661'
export const CUSTOM_COLOR_1 = '#CC3262'
export const CUSTOM_COLOR_3 = '#5630AA'
export const CUSTOM_COLOR_8 = '#FE019A'
export const CUSTOM_COLOR_9 = '#A9A9A9'
export const CUSTOM_COLOR_10 = '#5EBDD4'
export const TRANSPARENT = 'transparent'
