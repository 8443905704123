import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import {
  iconBusinessCenter,
  iconIosStats,
  iconLocationWhite,
  iconPersonWhite,
  iconPhoneWhite,
  iconProjectDiagram,
} from '../../assets/images/images'
import {
  START_DATE_OF_PREVIOUS_ORDER_HISTORY,
  MAP,
  PHONE,
} from '../../enums/common'
import { IClientDetailsInfoProps } from '../../interfaces/IClientDetails'
import ClientCipCode from '../clientCipcode/ClientCipcode'
import LinkTo from '../common/ui/LinkTo'
import DynamicFormattedMessage from '../common/ui/DynamicFormattedMessage'

export const ClientDetailsInfo: FC<IClientDetailsInfoProps> = ({
  name,
  address1,
  phoneNumber,
  cipCode,
  contactPerson,
  hasOnlyOneColumn,
  zipCode,
  gpsLatitude,
  gpsLongitude,
  city,
  potentiel2,
  targetType2,
  group,
  clientFilteredAppointments,
}) => {
  const intl = useIntl()
  const addressBlock = `${address1}${zipCode ? `, ${zipCode}` : ''}${
    city ? `, ${city}` : ''
  }`
  const hasCoordinates = gpsLatitude && gpsLongitude

  const filteredVisitReportsDate = START_DATE_OF_PREVIOUS_ORDER_HISTORY

  const date = new Date()
  const currentMonth = date.getMonth()
  const currentYear =
    currentMonth < 9 ? date.getFullYear() : date.getFullYear() + 1

  const toolTipText = intl.formatMessage(
    {
      id: 'clientFile.dataType.targetType2.label',
    },
    {
      year: currentYear,
    }
  )

  return (
    <div className="clientDetailsPageClientData hasBoxShadow withBackgroundColorPrimary">
      <p className="mb3 title">
        <span>
          {`${name} `}
          {cipCode && <ClientCipCode cipCode={cipCode} />}
        </span>
      </p>
      <div className="dFlex">
        <div
          className={`px1 flex ${hasOnlyOneColumn ? '' : 'hasRightSeparator'}`}
        >
          <p className="mb15 pb1 dFlex hasSeparator">
            <LinkTo
              type={MAP}
              hrefContent={
                hasCoordinates ? `${gpsLatitude},${gpsLongitude}` : addressBlock
              }
              customClass="fontMedium"
            >
              <img
                src={iconLocationWhite}
                alt="user location icon"
                className="mr1"
              />
              <span>{addressBlock}</span>
            </LinkTo>
          </p>
          <p className="mb15 pb1 dFlex hasSeparator">
            <LinkTo
              type={PHONE}
              hrefContent={phoneNumber}
              customClass="fontMedium"
            >
              <img src={iconPhoneWhite} alt="user phone icon" className="mr1" />
              <span>{phoneNumber}</span>
            </LinkTo>
          </p>
          <p>
            <img src={iconPersonWhite} alt="user person icon" className="mr1" />
            <span>{!contactPerson || contactPerson[0]?.name || '-'}</span>
          </p>
        </div>
        {!hasOnlyOneColumn && (
          <div className="flex px1">
            {targetType2 && (
              <div className="mb15 pb1 dFlex hasDivSeparator">
                <div data-tip={toolTipText}>
                  <img
                    src={iconBusinessCenter}
                    alt="business center icon"
                    className="mr1"
                  />
                </div>
                <span>{targetType2}</span>
              </div>
            )}
            {potentiel2 && (
              <p className="mb15 pb1 dFlex hasSeparator">
                <img src={iconIosStats} alt="ios stats icon" className="mr1" />
                <span>{potentiel2}</span>
              </p>
            )}
            {group && (
              <p className="mb15 pb1 dFlex hasSeparator">
                <img
                  src={iconProjectDiagram}
                  alt="project diagram icon"
                  className="mr1"
                />
                <span>{group}</span>
              </p>
            )}
            {clientFilteredAppointments && (
              <DynamicFormattedMessage
                id="clientFile.filteredVisitReportsTotal"
                values={{
                  date: filteredVisitReportsDate,
                  total: clientFilteredAppointments.total,
                }}
                tag="p"
                className="fontBold"
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
